import { CircularProgress } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import * as React from 'react';

export type CenterCircularProgressProps = {
    sx?: SxProps;
};

const CenterCircularProgress: React.FunctionComponent<CenterCircularProgressProps> = function ({
    sx,
}: CenterCircularProgressProps) {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default CenterCircularProgress;
